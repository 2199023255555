import Banner from "../../component/Banner/index";
import styled from "styled-components";

/* Banner 图 */
import banner_img from "../../assets/image/store_linking/poster.png";

/* 商家流量图片 */
import store_flow_img from "../../assets/image/store_linking/application.png";

/* 助力商家图片 */
import empower_img from "../../assets/image/store_linking/Helpbusinesses.png";

/* 合作收益图片 */
import income_img from "../../assets/image/store_linking/Cooperationincome.png";

/* 商家案例图片 */
import example_img1 from "../../assets/image/store_linking/Merchantcase1.png";
import example_img2 from "../../assets/image/store_linking/Merchantcase2.png";
import example_img3 from "../../assets/image/store_linking/Merchantcase3.png";

const EmpowerStoreAside = styled.section`
  p:first-child {
    color: #5ea0da;
    font-size: 1.4rem;
  }
  > section:nth-of-type(odd) p:first-child {
    color: #5b73b6;
  }
`;
const ExampleOuter = styled.section`
  p:nth-of-type(2) {
    color: #22a837;
    background-color: #d9f6e2;
  }
`;
const StoreLinking = function () {
  document.title = "商家接入-景区码";

  const example_arr = [
    {
      title: "深圳网红餐厅",
      hot_tip: "收益提升150%！",
      desc:
        "餐厅入驻景区码以后，通过代销锁客等功能，将以往忽略的用户流量收集变现，实现长期收益增值，景区码是我们的增粉利器！",
      img: example_img1,
    },
    {
      title: "上海社区教育机构",
      hot_tip: "学员报名数提升300%！",
      desc:
        "搞教育最头疼的就是招揽学员，平时通过以往的方式用户很反感，开销大，且不能保证效果。自从加入景区码活动平台，报名学员数激增，感谢景区码！",
      img: example_img2,
    },
    {
      title: "成都高端健身中心",
      hot_tip: "会员满意度提升200%！",
      desc:
        "鉴于本店场地宽裕，用户消费能力高等特点，跟景区码申请合作，落地充电宝，自动售货机，智慧口罩机等多个应用设备，不仅营业额提升明显，客户也好评如潮。",
      img: example_img3,
    },
  ];
  return (
    <section>
      <Banner img={banner_img} path='/about/contact'></Banner>
      {/* 流量生态 */}
      <section>
        <section className="text-center w-container mx-auto py-12">
          <p className="text-5xl">构建商家流量生态</p>
          <p className="text-2xl my-4">
            景区码为商家提供线上线下一体化的服务工具，协助商家通过支付、领卡、智能应用等服务场景锁定客户，持续增加商家收益。
          </p>
          <img src={store_flow_img} alt="img" />
        </section>
      </section>
      {/* 助力商家 */}
      <section className="bg-gray-100">
        <section className="w-container mx-auto py-12">
          <p className="text-3xl mb-8 text-center ">景区码助力商家</p>
          <section className="flex">
            <img src={empower_img} className="mr-8" alt="img" />
            <EmpowerStoreAside className="flex flex-col justify-between">
              <section>
                <p>长期收益</p>
                <p>
                  商家通过发放商家会员卡及特惠活动快速引流，
                  提高品牌知名度及用户的粘性。
                </p>
              </section>
              <section>
                <p>轻松锁客</p>
                <p>线上线下同步导流，多场景服务，深度锁定客户。</p>
              </section>
              <section>
                <p>精准营销</p>
                <p>让大数据成为商家决策管理的利器，为商家精准导流。</p>
              </section>
              <section>
                <p>多元化收益</p>
                <p>扫码购、线上分销、京东等平台200万商品+商品一键代销。</p>
              </section>
            </EmpowerStoreAside>
          </section>
        </section>
      </section>
      {/* 合作收益 */}
      <section>
        <section className="text-center w-container mx-auto py-12">
          <p className="text-5xl">合作收益</p>
          <p className="text-2xl my-4">百亿流量支持/多渠道创收/码上赋能</p>
          <img src={income_img} alt="img" />
        </section>
      </section>
      {/* 商家案例 */}
      <section className="bg-gray-100">
        <section className="w-container mx-auto py-12">
          <p className="text-5xl mb-8 text-center ">商家案例</p>
          <ExampleOuter className="grid grid-cols-3 gap-2 text-center">
            {example_arr.map((e, i) => {
              return (
                <section key={i} className="bg-white">
                  <img src={e.img} alt="img" />
                  <p className="  text-2xl px-4 pt-4">{e.title}</p>
                  <p className=" text-xl my-2 px-4">{e.hot_tip}</p>
                  <p className=" text-lg text-justify px-4 pb-4">{e.desc}</p>
                </section>
              );
            })}
          </ExampleOuter>
        </section>
      </section>
    </section>
  );
};
export default StoreLinking;

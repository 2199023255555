import Banner from "../../component/Banner/index";
import styled from "styled-components";

/* Banner 图 */
import banner_img from "../../assets/image/scenic_cooperation/poster.png";

/* 一码通行图片 */
import passing_img1 from "../../assets/image/scenic_cooperation/Empower1.png";
import passing_img2 from "../../assets/image/scenic_cooperation/Empower2.png";
import passing_img3 from "../../assets/image/scenic_cooperation/Empower3.png";
import passing_img4 from "../../assets/image/scenic_cooperation/Empower4.png";
import passing_img5 from "../../assets/image/scenic_cooperation/Empower5.png";
import passing_img6 from "../../assets/image/scenic_cooperation/Empower6.png";
import passing_img7 from "../../assets/image/scenic_cooperation/Empower7.png";
import passing_img8 from "../../assets/image/scenic_cooperation/Empower8.png";

/* 营销工具图片 */
import marketing_img from "../../assets/image/scenic_cooperation/tool.png";

/* 周边商家生态图片 */
import store_ecosystem_img1 from "../../assets/image/scenic_cooperation/Surrounding1.png";
import store_ecosystem_img2 from "../../assets/image/scenic_cooperation/ban_canting.png";

/* 大数据图片 */
import big_data_img from "../../assets/image/scenic_cooperation/BigData.png";

/* 底部客服图片 */
import hotline_img from "../../assets/image/scenic_cooperation/CooperationHotline.png";

const NearbyEcosystem = styled.section`
  padding: 2rem 9rem 0 3rem;
  font-size: 1.1rem;
  color: #44A651;
  background: url("${store_ecosystem_img2}") no-repeat right bottom #f4fdfa;
  &::before {
    content: "";
    position: absolute;
    margin-left: -30px;
    margin-top: 5px;
    width: 16px;
    height: 16px;
    background-color: #44A651;
    margin-right: 10px;
  }
`;

const ScenicCooperation = function () {
  document.title = "景区合作-景区码";

  const passing_arr = [
    {
      title: "信息推送 ",
      desc: "针对精准人群进行推广覆盖",
      img: passing_img1,
    },
    {
      title: "分时预约",
      desc: "在线预约购票，实名制助力景区抗疫",
      img: passing_img2,
    },
    {
      title: "刷码入园",
      desc: "支持刷码/刷脸入园，快速入园免排队",
      img: passing_img3,
    },
    {
      title: "刷码乘车",
      desc: "无需到站台购买，随时随地，刷码上车",
      img: passing_img4,
    },
    {
      title: "景区导游",
      desc: "语音讲解享受vip待遇，游玩不再蒙圈",
      img: passing_img5,
    },
    {
      title: "演出选座",
      desc: "景区剧场在线选座，免排队更便捷",
      img: passing_img6,
    },
    {
      title: "刷码购物",
      desc: "购物零负担，送礼更轻松",
      img: passing_img7,
    },
    {
      title: "数据分析",
      desc: "沉淀游客数据，提升服务水平",
      img: passing_img8,
    },
  ];
  return (
    <section>
      <Banner img={banner_img} path="/about/contact"></Banner>
      {/* 赋能景区，一码通行 */}
      <section>
        <section className="text-center w-container mx-auto py-12">
          <p className="text-3xl">赋能景区 一码通行</p>
          <p className="text-xl my-4">科技融入生活 让旅游 更自由</p>
          <section className="grid grid-rows-2 grid-cols-4 gap-4">
            {passing_arr.map((e, i) => {
              return (
                <section key={i} className=" text-center">
                  <img src={e.img} alt="img" />
                  <p className=" text-xl my-1">{e.title}</p>
                  <p className="text-gray-400 text-sm">{e.desc}</p>
                </section>
              );
            })}
          </section>
        </section>
      </section>
      {/* 营销工具 */}
      <section>
        <section className="text-center w-container mx-auto py-12">
          <p className="text-3xl">景区码大会员营销工具</p>
          <p className="text-xl my-4">为景区增收/增粉</p>
          <img src={marketing_img} alt="img" />
        </section>
      </section>
      {/* 周边商家生态 */}
      <section>
        <section className="text-center w-container mx-auto py-12">
          <p className="text-3xl">联合周边商家 共建产业生态</p>
          <p className="text-xl my-4">景区商家互相导流 实现收益最大化</p>
          <section className="flex justify-between">
            <img src={store_ecosystem_img1} alt="img" />
            <NearbyEcosystem className="flex-1 ml-3 text-justify">
              景区码为景区提供的“景区自主服务小程序”。这个小程序提供景区各类服务连接C端游客，增加景区内二消转化。同时将景区内的游客导流到景区周边的商家进行消费，景区能获得消费收益，实现大交通+大文旅+大消费的产业生态圈。
            </NearbyEcosystem>
          </section>
        </section>
      </section>
      {/* 大数据生态 */}
      <section>
        <section className="text-center w-container mx-auto py-12">
          <p className="text-3xl">景区码大数据多维度呈现</p>
          <p className="text-xl my-4">让大数据成为决策者的利器</p>
          <img src={big_data_img} alt="img" />
        </section>
      </section>
      <img src={hotline_img} alt="img" />
    </section>
  );
};
export default ScenicCooperation;

import styled from "styled-components";
import { Link } from "react-router-dom";

const BannerImg = styled.img`
  height: 37.375rem;
`;
const Banner = function (props) {
  return (
    <Link to={props.path ?? "#"}>
      <BannerImg src={props.img}></BannerImg>
    </Link>
  );
};
export default Banner;

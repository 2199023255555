import { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Banner from "../../component/Banner/index";
import PartnerLayout from "./PartnerLayout";
/* banner 图 */
import banner_img from "../../assets/image/index/poster2.png";

/* 景区码能力介绍图片 */
import ability_1 from "../../assets/image/index/Identity code.png";
import ability_2 from "../../assets/image/index/Payment code.png";
import ability_3 from "../../assets/image/index/Credit code.png";
import ability_4 from "../../assets/image/index/Health code .png";

/* 视频区域图片 */
import video_bg1 from "../../assets/image/index/Building an industrial ecosystem.png";
import video_bg2 from "../../assets/image/index/Service area.png";
import video_bg3 from "../../assets/image/index/Service business.png";
import video_bg4 from "../../assets/image/index/serve the society.png";

/* 景区赋能-图片 */
import energize_img1 from "../../assets/image/index/Four Empowerments .png";
import energize_img2 from "../../assets/image/index/Four Empowerments2.png";
import energize_img3 from "../../assets/image/index/Four Empowerments3.png";
import energize_img4 from "../../assets/image/index/Four Empowerments4.png";

/* 合作景区图片 */
import partner_scenic_img1 from "../../assets/image/index/Scenic1.png";
import partner_scenic_img2 from "../../assets/image/index/Scenic2.png";
import partner_scenic_img3 from "../../assets/image/index/Scenic3.png";
import partner_scenic_img4 from "../../assets/image/index/Scenic4.png";
import partner_scenic_img5 from "../../assets/image/index/Scenic5.png";
import partner_scenic_img6 from "../../assets/image/index/Scenic6.png";

/* 合作酒店图片 */
import partner_hotel_img1 from "../../assets/image/index/Hotel1.png";
import partner_hotel_img2 from "../../assets/image/index/Hotel2.png";
import partner_hotel_img3 from "../../assets/image/index/Hotel3.png";
import partner_hotel_img4 from "../../assets/image/index/Hotel4.png";
import partner_hotel_img5 from "../../assets/image/index/Hotel5.png";
import partner_hotel_img6 from "../../assets/image/index/Hotel6.png";

/* 合作商家图片 */
import partner_store_img1 from "../../assets/image/index/Merchant1.png";
import partner_store_img2 from "../../assets/image/index/Merchant2.png";
import partner_store_img3 from "../../assets/image/index/Merchant3.png";
import partner_store_img4 from "../../assets/image/index/Merchant4.png";
import partner_store_img5 from "../../assets/image/index/Merchant5.png";
import partner_store_img6 from "../../assets/image/index/Merchant6.png";

/* 底部智慧文旅 banner 图 */
import intelligent_banner from "../../assets/image/index/postersmall.png";

import axios from "axios";

const VideoAside1 = styled.section`
  background: url("${video_bg1}") no-repeat left top / 100% 100% #ddf4ec;
  .label,
  & + section .label {
    color: #52ab5e;
    font-size: 24px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .label::before,
  & + section .label::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 23px;
    margin-right: 5px;
    vertical-align: middle;
    background-color: #52ab5e;
  }
`;
const VideoAside2 = styled.section`
  background: url("${video_bg2}") no-repeat left top / 100% 100% #ddf4ec;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  & ~ section {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
`;
const VideoAside3 = styled.section`
  background: url("${video_bg3}") no-repeat left top / 100% 100% #ddf4ec;
`;
const VideoAside4 = styled.section`
  background: url("${video_bg4}") no-repeat left top / 100% 100% #ddf4ec;
`;
const Video = styled.video`
  width: 746px;
  height: 100%;
`;
const TailingLi = styled.div`
  position: relative;
  &.active::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    bottom: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #01d267;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
`;
const ArticleLi = styled.li`
  .img-outer {
    width: 154px;
    height: 154px;
    overflow: hidden;
  }
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
    .title {
      color: #01d267;
    }
  }
`;
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      energize_index: 0,
      energize_banner: energize_img1,
      news_list: [],
    };
    this.setNewsList();

  document.title = "景区码_智慧文旅，一码畅享 !景区码官网_智慧景区_互联网+旅游景区_景区微信订票_手机刷码入园";
  }
  async fetchNewsList() {
    return await axios("v1/news/get-list", {
      params: {
        page: 1,
        page_size: 4,
        cate_id: 0,
      },
    });
  }
  async setNewsList() {
    const { data: news } = await this.fetchNewsList();
    this.setState({
      news_list: news.list,
    });
  }
  ability_arr = [
    {
      title: "身份码",
      desc: "公安部接入身份证、出游不怕忘带身份证",
      icon: ability_1,
    },
    {
      title: "支付码",
      desc: "支持双盲支付、信号不好也可付款",
      icon: ability_2,
    },
    {
      title: "信用码",
      desc: "征信联动，先游后付，余额不足不尴尬",
      icon: ability_3,
    },
    {
      title: "健康码",
      desc: "甄别游客来源、快速关联预警",
      icon: ability_4,
    },
  ];
  energize_arr = [
    {
      title: "服务赋能",
      banner: energize_img1,
    },
    {
      title: "流量赋能",
      banner: energize_img2,
    },
    {
      title: "创收赋能",
      banner: energize_img3,
    },
    {
      title: "数据赋能",
      banner: energize_img4,
    },
  ];
  partner_scenic_arr = [
    {
      title: "东部华侨城",
      img: partner_scenic_img1,
    },
    {
      title: "丹霞山风景名胜区",
      img: partner_scenic_img2,
    },
    {
      title: "黄山",
      img: partner_scenic_img3,
    },
    {
      title: "铁道游击队纪念公园",
      img: partner_scenic_img4,
    },
    {
      title: "世界之窗",
      img: partner_scenic_img5,
    },
    {
      title: "锦绣中华民俗文化村",
      img: partner_scenic_img6,
    },
  ];
  partner_hotel_arr = [
    {
      title: "星语星愿酒店公寓",
      img: partner_hotel_img1,
    },
    {
      title: "上海华美国际酒店",
      img: partner_hotel_img2,
    },
    {
      title: "杭州碧海云天宾馆",
      img: partner_hotel_img3,
    },
    {
      title: "深圳圣淘沙酒店",
      img: partner_hotel_img4,
    },
    {
      title: "云南八宝贡酒店",
      img: partner_hotel_img5,
    },
    {
      title: "北京华尔顿酒店",
      img: partner_hotel_img6,
    },
  ];
  partner_store_arr = [
    {
      title: "运动健身",
      img: partner_store_img1,
    },
    {
      title: "休闲娱乐",
      img: partner_store_img2,
    },
    {
      title: "养生健康",
      img: partner_store_img3,
    },
    {
      title: "亲子教育",
      img: partner_store_img4,
    },
    {
      title: "餐饮美食",
      img: partner_store_img5,
    },
    {
      title: "美容美发",
      img: partner_store_img6,
    },
  ];
  handleClickEnergize(index) {
    this.setState({
      energize_index: index,
      energize_banner: this.energize_arr[index].banner,
    });
  }
  scrollToTop() {
    document.documentElement.scrollTop = 0;
  }
  render() {
    return (
      <main>
        <Banner img={banner_img}></Banner>
        {/* 景区码能力展示 */}
        <section className="bg-white w-container mx-auto text-center pb-16">
          <p className=" text-3xl pt-20 pb-16">景区码四大能力</p>
          <ul className="flex justify-between">
            {this.ability_arr.map((e, i) => {
              return (
                <li
                  className="flex justify-center items-center flex-col w-48"
                  key={i}
                >
                  <img src={e.icon} className="w-48 h-48" alt="img" />
                  <p className=" text-xl mt-7">{e.title}</p>
                  <span className="text-gray-400 inline-block w-5/6 mt-1">
                    {e.desc}
                  </span>
                </li>
              );
            })}
          </ul>
        </section>
        {/* 景区码视频介绍 */}
        <section className="bg-gray-200 py-16">
          <section className="w-container mx-auto">
            <p className="text-3xl text-center" >景区码：我是文旅行业码</p>
            <p className=" text-gray-500 text-lg mt-4 mb-16 w-2/3 mx-auto text-center">
              景区码联动全域景区和社区，实现一码通行、一码互联、一码跨界，集合电子身份码、支付码、信用码、健康码等大数据能力，为人们提供码上生活的便利
            </p>
            <section className="flex">
              <section className="flex-1 flex flex-col">
                <VideoAside1 className="mb-4 pr-28 pb-4">
                  <div className="label">建设产业生态圈</div>
                  <p className="text-lg pl-14">实现“智慧全域旅游”场景应用</p>
                  <p className="text-gray-400 pl-14 text-sm">
                    打造旅游产业生态圈，实现游客一码通行完美体验，助力产业经济发展。
                  </p>
                </VideoAside1>
                <section className="row-start-2 row-end-3 flex-1 ">
                  <div className="label bg-gradient-to-r from-green-100 to-green-50 ">
                    三大服务
                  </div>
                  <VideoAside2>
                    <p className="text-lg pl-14">服务景区</p>
                    <p className="text-gray-400 pl-14 text-sm   w-3/4">
                      打造旅游产业生态圈，实现游客一码通行完美体验，助力产业经济发展。
                    </p>
                  </VideoAside2>
                  <VideoAside3>
                    <p className="text-lg pl-14">服务商家</p>
                    <p className="text-gray-400 pl-14 text-sm   w-3/4">
                      通过景区码查看餐饮、酒店、健身等优惠，
                      开开心心下单、到店扫码核销，引流增收。
                    </p>
                  </VideoAside3>
                  <VideoAside4>
                    <p className="text-lg pl-14">服务社会</p>
                    <p className="text-gray-400 pl-14 text-sm  w-3/4">
                      通过智慧技术，以民为本，爱心扶贫；
                      联动教育资源，为千万家庭带去福音。
                    </p>
                  </VideoAside4>
                </section>
              </section>
              <section className=" ml-4">
                <Video
                  src="http://www.cityfx.cn/static/default/home/assets/video/jqm2.mp4"
                  controls
                ></Video>
              </section>
            </section>
          </section>
        </section>
        {/* 景区码四大赋能 */}
        <section className="w-container mx-auto py-8 pt-16">
          <p className="text-3xl text-center">四大赋能</p>
          <p className=" text-gray-500 text-lg mt-4 mb-8 w-2/3 mx-auto text-center">
            连接C端游客，增加景区内二销转化，实现大交通+大文旅+大消费的产业生态圈
          </p>
          <section className=" border-b-2 border-main-green-400 flex justify-center">
            {this.energize_arr.map((e, i) => {
              return (
                <TailingLi
                  className={
                    "text-xl px-12 py-3 " +
                    (this.state.energize_index === i ? " active" : "")
                  }
                  onMouseEnter={this.handleClickEnergize.bind(this, i)}
                  key={i}
                >
                  {e.title}
                </TailingLi>
              );
            })}
          </section>
          <img src={this.state.energize_banner} className="mt-8" alt="img" />
        </section>
        {/* 合作景区 */}
        <PartnerLayout
          arr={this.partner_scenic_arr}
          title="合作景区"
          desc="8000+智慧旅游项目案列 涵盖5A、4A景区"
        ></PartnerLayout>
        {/* 合作酒店 */}
        <PartnerLayout
          arr={this.partner_hotel_arr}
          title="合作酒店"
          desc="用户可享受100万+特惠酒店"
        ></PartnerLayout>
        {/* 合作商家 */}
        <PartnerLayout
          arr={this.partner_store_arr}
          title="合作商家"
          desc="会员可享受30万+商家VIP活动"
        ></PartnerLayout>
        <section className="w-container mx-auto py-8">
          <img src={intelligent_banner} alt="img" />
        </section>
        <section className="bg-gray-200 py-16">
          <section className="w-container mx-auto">
            <p className="text-3xl text-center">企业新闻与行业资讯</p>
            <Link
              to="/news"
              className="text-right block my-5"
              onClick={this.scrollToTop}
            >
              查看更多
              <svg
                className="w-4 h-4 inline-block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <section>
              <ul className="grid grid-rows-2 grid-cols-2 gap-3">
                {this.state.news_list.map((e, i) => {
                  return (
                    <ArticleLi
                      className=" bg-white border-solid border border-gray-300 p-1.5"
                      key={i}
                    >
                      <Link
                        to={`/news?news_id=${e.id}`}
                        className="flex"
                        onClick={this.scrollToTop}
                      >
                        <section className="mr-4 flex-shrink-0 img-outer">
                          <img
                            className="w-40 h-40 object-cover"
                            src={e.img}
                            alt="img"
                          />
                        </section>
                        <section>
                          <p className="transition-all py-4 title">{e.title}</p>
                          <p className="text-gray-400 text-sm py-2">{e.desc}</p>
                          <p className="text-gray-400 text-sm">{e.date}</p>
                        </section>
                      </Link>
                    </ArticleLi>
                  );
                })}
              </ul>
            </section>
          </section>
        </section>
      </main>
    );
  }
}
export default Index;

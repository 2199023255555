import Banner from "../../component/Banner/index";
import styled from "styled-components";

import banner_img from "../../assets/image/ecosystem_cooperation/poster.png";

/* 应用合作图片 */
import app_img1 from "../../assets/image/ecosystem_cooperation/Cooperation1.png";
import app_img2 from "../../assets/image/ecosystem_cooperation/Cooperation2.png";
import app_img3 from "../../assets/image/ecosystem_cooperation/Cooperation3.png";
import app_img4 from "../../assets/image/ecosystem_cooperation/Cooperation4.png";
import app_img5 from "../../assets/image/ecosystem_cooperation/Cooperation5.png";
import app_img6 from "../../assets/image/ecosystem_cooperation/Cooperation6.png";
import app_img7 from "../../assets/image/ecosystem_cooperation/Cooperation.png";

/* 4 大支持图片 */
import supporting_img from "../../assets/image/ecosystem_cooperation/bg_Videoscreen2.png";
import supporting_img2 from "../../assets/image/ecosystem_cooperation/Videoscreen.png";

/* 代理商图片 */
import agent_distribution_img from "../../assets/image/ecosystem_cooperation/Serviceprovider.png";

/* 盈利图片 */
import income_img from "../../assets/image/ecosystem_cooperation/Profitablesector.png";

/* 5 大扶持政策图片 */
import policy_img1 from "../../assets/image/ecosystem_cooperation/1SupportPolicies.png";
import policy_img2 from "../../assets/image/ecosystem_cooperation/2SupportPolicies.png";
import policy_img3 from "../../assets/image/ecosystem_cooperation/3SupportPolicies.png";
import policy_img4 from "../../assets/image/ecosystem_cooperation/4SupportPolicies.png";
import policy_img5 from "../../assets/image/ecosystem_cooperation/5SupportPolicies.png";
import policy_active_icon1 from "../../assets/image/ecosystem_cooperation/policy1.png";
import policy_active_icon2 from "../../assets/image/ecosystem_cooperation/policy2.png";
import policy_active_icon3 from "../../assets/image/ecosystem_cooperation/policy3.png";
import policy_active_icon4 from "../../assets/image/ecosystem_cooperation/policy4.png";
import policy_active_icon5 from "../../assets/image/ecosystem_cooperation/policy5.png";

/* 申请图片 */
import apply_img from "../../assets/image/ecosystem_cooperation/Applynow.png";
import { Link } from "react-router-dom";

const Support = styled.section`
  height: 500px;
  background: url("${supporting_img}") no-repeat left top;
`;
const Apply = styled.section`
  height: 230px;
  background: url("${apply_img}") no-repeat left top;
`;
const AppCooText = styled.article`
  position: relative;
  text-align: left;
  padding: 1rem 9rem 3rem 3rem;
  border: 1px solid #f4f4f4;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 1rem;
    width: 10px;
    height: 20px;
    background-color: #52ab5e;
  }
`;
const HelpPolicy = styled.section`
  .flex-1 {
    position: relative;
    overflow: hidden;
    &:hover {
      .showup {
        top: 0;
      }
    }
  }
  .showup {
    position: absolute;
    top: 999px;
    left: 0;
    height: 100%;
    background-color: #f4f6fa;
    /* z-index: 2; */
    transition: all 0.4s;
    .head {
      display: flex;
      height: 30%;
      /* justify-content: center; */
      align-items: center;
      padding: 0.8rem;
      background-color: #60ce8f;
      font-size: 1.5rem;
      img {
        margin-right: 1rem;
      }
    }
    p {
      height: 70%;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
const EcosystemCooperation = function () {
  document.title = "生态合作-景区码";

  const app_cooperation_arr = [
    {
      img: app_img1,
      text: "智慧口罩机",
    },
    {
      img: app_img2,
      text: "共享代步车",
    },
    {
      img: app_img3,
      text: "共享休息仓",
    },
    {
      img: app_img4,
      text: "智慧储物柜",
    },
    {
      img: app_img5,
      text: "共享充电宝",
    },
    {
      img: app_img6,
      text: "智能饮料机",
    },
  ];
  const help_policy_arr = [
    {
      img: policy_img1,
      text: "经营支持",
      desc:
        "给予更优政策，全力帮助地区服务商开拓市场，开发景区，协助地区服务商 搭建团队架构",
      icon: policy_active_icon1,
    },
    {
      img: policy_img2,
      desc: "免费提供产品使用培训、市场推广话术培训、团队管理培训等",
      icon: policy_active_icon2,
      text: "培训支持",
    },
    {
      img: policy_img3,
      desc:
        "强力的线下路演助推，提高景区码知名度，使地区服务商更快更顺 利开展",
      icon: policy_active_icon3,
      text: "宣传支持",
    },
    {
      img: policy_img4,
      desc: "总部为每个合作伙伴提供强大的后台管理，数据可实时查看和跟进",
      icon: policy_active_icon4,
      text: "技术支持",
    },
    {
      img: policy_img5,
      desc:
        "景区码定期委派市场团队到现场帮扶，协助服务商组建地推团队，为地区服务商保驾护航",
      icon: policy_active_icon5,
      text: "团队支持",
    },
  ];
  return (
    <section>
      <Banner img={banner_img} path="/about/contact"></Banner>
      {/* 应用合作 */}
      <section className="text-center w-container mx-auto">
        <p className="pt-12 text-3xl">应用合作</p>
        <p className=" py-6 text-xl">增加触达场景 提升景区收益</p>
        <section className="grid grid-cols-2 grid-flow-row gap-4 py-8">
          <AppCooText>
            景区码为应用伙伴接入景区、商业综合体，展现服务场景；同时，通过红包、卡券等互动功能增加客户粘性，从而提升收益。
          </AppCooText>
          <section className=" row-start-2 flex flex-wrap justify-between">
            {app_cooperation_arr.map((e, i) => (
              <section className="" key={i}>
                <img src={e.img} alt="img" />
                <p>{e.text}</p>
              </section>
            ))}
          </section>
          <img src={app_img7} alt="img" className=" row-span-2 ml-auto" />
        </section>
      </section>

      {/* 四大支持政策 */}
      <Support className="text-3xl text-center">
        <p className='py-12'>应用伙伴四大支持政策</p>
        <img src={supporting_img2} alt="img" className="mx-auto" />
      </Support>
      {/* 服务、代理商 */}
      <section className="w-container mx-auto flex justify-between items-center py-12">
        <img src={agent_distribution_img} alt="img" />
        <section className="ml-8">
          <p className="text-3xl">服务商、合伙人</p>
          <p className="text-lg mt-4">
            在全国34个省景区码拥有了3000多个地区服务商，30多万个合伙人。为用户、景区、商家合作伙伴提供服务携手共赢。
          </p>
        </section>
      </section>
      {/* 九大盈利板块 */}
      <section className="bg-gray-100 pb-12">
        <section className="w-container mx-auto text-center">
          <p className="text-3xl py-12">九大盈利板块</p>
          <img src={income_img} alt="img" />
        </section>
      </section>
      {/* 景区码5大扶持政策 */}
      <section className="w-container mx-auto pb-12">
        <p className="text-3xl py-12 text-center">景区码5大扶持政策</p>
        <HelpPolicy className="flex text-center divide-x divide-gray-200">
          {help_policy_arr.map((e, i) => (
            <section className="flex-1 py-4" key={i}>
              <img src={e.img} alt="img" className=" w-36 h-28 mx-auto" />
              <p className="text-3xl mt-8">{e.text}</p>

              <section className="showup">
                <section className="head">
                  <img src={e.icon} alt="img" />
                  <span>{e.text}</span>
                </section>
                <p>
                  <span>{e.desc}</span>
                </p>
              </section>
            </section>
          ))}
        </HelpPolicy>
      </section>
      {/* 招募申请 */}
      <Apply className="text-white text-center pt-8">
        <p className="font-bold text-4xl">景区码向全国招募生态合作伙伴</p>
        <p className="text-3xl py-4">共同构建文旅生态圈，共享市场资源！</p>
        <Link
          to="/about/contact"
          className="bg-main-green-400 px-20 rounded-xl text-gray-700 shadow-inner py-4 text-2xl inline-block"
        >
          立刻申请
        </Link>
      </Apply>
    </section>
  );
};
export default EcosystemCooperation;

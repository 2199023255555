import { Component } from "react";

import { HashRouter as Router, Switch, Route } from "react-router-dom";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import { BASE_URL } from "./constant";

import Header from "./component/Header/index";
import Footer from "./component/Footer/index";
import Contact from "./component/contact/index";

import Index from "./pages/index/index";
import ScenicCooperation from "./pages/scenic_cooperation/index";
import CommunityCooperation from "./pages/community_cooperation/index";
import StoreLinking from "./pages/store_linking/index";
import EcosystemCooperation from "./pages/ecosystem_cooperation/index";
import News from "./pages/news/index";
import PolicyFiles from "./pages/policy_files/policy_files";
import About from "./pages/about/index";
import IncompatiblePage from "./pages/browser_download";

import axios from "axios";
axios.defaults.baseURL = BASE_URL;
class App extends Component {
  constructor(props) {
    super(props);
    this.is_browser_supported = this.judgeBrowserCompatibility();
  }
  judgeBrowserCompatibility() {
    const ua = navigator.userAgent;
    return ua.indexOf("Trident") > -1;
  }
  routes = [
    {
      path: "/",
      exact: true,
      main: Index,
    },
    {
      path: "/scenic_cooperation",
      main: ScenicCooperation,
    },
	{
	  path: "/community_cooperation",
	  main: CommunityCooperation,
	},
    {
      path: "/store_linking",
      main: StoreLinking,
    },
    {
      path: "/ecosystem_cooperation",
      main: EcosystemCooperation,
    },

    {
      path: "/news",
      main: News,
    },
    {
      path: "/policy_files",
      main: PolicyFiles,
    },
    {
      path: "/about",
      main: About,
    }
  ];
  render() {
    if (this.is_browser_supported) {
      return <IncompatiblePage />;
    }
    return (
    /* basename="/build"*/
      <Router>
        <Header></Header>
        <Switch>
          {this.routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}
        </Switch>
        <Contact></Contact>
        <Footer></Footer>
      </Router>
    );
  }
}
export default App;

import icon_contact from '../../assets/image/index/icon_chat.png';

const Contact = function() {
  return (
    <aside className='fixed right-2 top-1/2 transform -translate-y-1/2 bg-white cursor-default group'>
      <div className='bg-main-green-400 p-2'>
        <img className='mx-auto' src={icon_contact} alt='contact icon' />
      </div>
      <p className=' bg-black text-main-green-400 text-center text-lg'>联系<br />我们</p>
      <img className='absolute invisible -left-40 -top-5 w-36 h-40 max-w-none qr-code transition-all delay-150 group-hover:visible group-hover:delay-0' src="http://ximg.cityfx.cn/44f7/96d/16145663376550.png" alt='img' />
    </aside>
  )
}
export default Contact;
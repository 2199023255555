/* 加入我们 */
const Join = function () {
  return (
    <section className=" w-2/3 flex-shrink-0">
      <h2 className="text-2xl font-bold mb-8">加入我们</h2>
      <h6 className=" text-main-green-400 mb-4">研发类</h6>
      <p className="text-lg">PHP开发工程师</p>
      <p>工作地点: 深圳南山 </p>
      <p>人数:2人</p>
      <p>简历请投递：KF@cityfx.cn</p>
      <br></br>
      <p>职位描述:</p>
      <p>1、负责公司互联网社区特性和运营平台的设计和开发；</p>
      <p>2、对现有的Web业务系统进行维护、升级改造和性能优化；</p>
      <p>3、与团队其他角色紧密配合工作，共同创造稳定易用的产品；</p>
      <br></br>
      <p>岗位要求:</p>
      <p>1、3年以上的Web后端开发经验，行业背景不限，大厂开发经验优先；</p>
      <p>2、熟练使用MySQL，良好的数据库设计和丰富的优化经验；</p>
      <p>3、熟悉NoSQL技术(redis，memcached等）；</p>
      <p>4、有Web安全意识，熟悉常见的Web安全问题以及防御措施；</p>
      <p>5、熟悉linux技术；</p>
      <p>
        6、有较强的学习能力，有良好的沟通能力以及团队协作精神，能够承担工作压力。
      </p>
    </section>
  );
};

export default Join;
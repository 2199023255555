
/* 联系我们 */
const Contact = function () {
  return (
    <section className=" w-2/3 flex-shrink-0">
      <h2 className="text-2xl font-bold mb-8">联系我们</h2>
      <p>深圳市景区码科技有限公司</p>
      <p>电话: 0755-8920 1956</p>
      <p>地址:深圳市南山区深南大道9998号万利达大厦23楼</p>
      <p>合作意向邮箱: KF@cityfx.com</p>
      
    </section>
  );
};
export default Contact;
/* 简介 */
const Intro = function () {
  return (
    <section className=" w-2/3 flex-shrink-0">
      <h2 className="text-2xl font-bold mb-8">公司简介</h2>
      <p className="mb-8">
        景区码公司是一家提供"互联网+文旅"解决方案的国家级高科技企业，我们以电子身份码、支付码、信用码、健康码等聚合技术，整合文旅大数据资源,构建了以社交圈为基础的一码通移动互联网平台。同时通过人机互联广泛连接相关服务场景,打造品质安全的文旅生活生态圈，创建了城市数据化、景区智慧化、商业共享化、管理可视化的数字生活场景，真正实现了人们一码畅享天下的品质文旅新体验！
      </p>
      <img
        src="http://www.cityfx.cn/static/default/home/assets/images/contact/main.jpg"
        alt="景区码图片"
      />
    </section>
  );
};
export default Intro;

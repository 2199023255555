import axios from "axios";
import { useEffect, useState } from "react";
import Banner from "../../component/Banner/index";
import banner_img from "../../assets/image/policy_files/poster.png";

export default function PolicyFiles() {
  const [files, setFiles] = useState([]);

  useEffect(async () => {
    const files = await fetchFiles();
    setFiles(files);
  }, []);

  async function fetchFiles() {
    const res = await axios.get("mall/slave/index", {
      params: {
        wx_type_id: 99,
      },
    });

    const files = res.data.data.result;

    return files;
  }

  return (
    <section>
        <Banner img={banner_img}></Banner>
    <section className="w-container mx-auto py-20 px-16">
      
      <ul className=" divide-y divide-green-100">
        {files.map((e, i) => {
          return (
            <li key={i} className="flex justify-between p-4">
              <p>{e.name}</p>
              <div className="group">
                <a
                  // download={e.file}
                  href={e.file}
                  target="_blank"
                  className="flex items-center px-5 py-1 bg-green-100 transition  group-hover:bg-main-green-400 group-hover:text-white rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  <span className="pl-1">下载</span>
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
    </section>
  );
}

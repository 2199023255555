/* 产品介绍 */
import product_img1 from '../../assets/image/about/product1.png';
const Product = function () {
  return (
    <section className=" w-2/3 flex-shrink-0">
      <h2 className="text-2xl font-bold mb-8">产品介绍</h2>
      <p>
        景区码是领先的文旅生活一码通平台，我们集合电子身份码、支付码、信用码、健康码等基础能力，为人们提供码上生活的便利，为景区、社区、商家等文旅场景提供强大的大会员数字营销工具。
        我们为景区提供标准化的引流、预约、排队、购物、社交分享等技术服务，我们为社区、商家、农户提供本地优质生活连接通道，我们让机器更懂我们的需求，我们致力于给人们提供品质文旅生活。
      </p>
      <img className='py-4' src={product_img1} alt='img' />
    </section>
  );
};
export default Product;

import styled from "styled-components";

const ALink = styled.a`
  color: rgb(0, 0, 238);
`;
const BrowserDownload = function () {
  const browsers = [
    {
      site: "https://www.mozilla.org/zh-CN/firefox/new/",
      title: "Firefox 浏览器",
      icon:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/1200px-Firefox_logo%2C_2019.svg.png",
    },
    {
      site: "https://www.google.cn/chrome/",
      title: "Google Chrome 浏览器",
      icon:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/1200px-Google_Chrome_icon_%28September_2014%29.svg.png",
    },
    {
      site: "https://www.microsoft.com/zh-cn/edge",
      title: "Microsoft Edge 浏览器",
      icon:
        "https://upload.wikimedia.org/wikipedia/en/thumb/9/98/Microsoft_Edge_logo_%282019%29.svg/1200px-Microsoft_Edge_logo_%282019%29.svg.png",
    },
  ];
  return (
    <>
      <p className="text-4xl py-48 text-center">
        您的浏览器版本过低，请更新浏览器
      </p>
      <section className="w-container mx-auto flex justify-around">
        {browsers.map((e, i) => (
          <ALink href={e.site} key={i} target="_blank" rel="noreferrer">
            <img className="w-20 mx-auto mb-4" src={e.icon} alt="download" />
            <span>{e.title}</span>
          </ALink>
        ))}
      </section>
    </>
  );
};
export default BrowserDownload;

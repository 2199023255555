import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

/* 引入图片 */
import Logo from "../../assets/image/index/logo.png";
import Icon_phone from "../../assets/image/index/icon_iphone.png";

const Nav = styled.nav`
  height: 90px;
`;
const H1 = styled.h1`
  text-indent: -999px;
  span {
    position: absolute;
  }
`;

const Header = function () {
  const location = useLocation();
  const current_path = location.pathname;

  const nav_arr = [
    {
      title: "首页",
      to: "/",
    },
    {
      title: "景区合作",
      to: "/scenic_cooperation",
    },
	{
	  title: "智慧社区",
	  to: "/community_cooperation",
	},
    {
      title: "商家接入",
      to: "/store_linking",
    },
    {
      title: "生态合作",
      to: "/ecosystem_cooperation",
    },
    {
      title: "最新资讯",
      to: "/news",
    },
    // {
    //   title: "资料下载",
    //   to: "/policy_files",
    // },
    {
      title: "关于我们",
      to: "/about",
    },
  ];
  return (
    <header className="bg-black">
      <Nav className="w-container mx-auto flex items-center">
        <Link to="/" className=" mr-auto">
          <H1>
            <span>景区码</span>
            <img src={Logo} alt="景区码" />
          </H1>
        </Link>
        <section className="flex text-main-green-400 items-center">
          {nav_arr.map((e, i) => {
            return (
              <Link
                to={e.to}
                className={
                  "p-4 hover:text-gray-300 transition-all " +
                  (current_path === e.to ? "text-gray-300" : "")
                }
                key={i}
              >
                {e.title}
              </Link>
            );
          })}
          <div className=" pl-12">
            <p className="font-bold">
              <img src={Icon_phone} className=" absolute -ml-6" alt="phone" />
              0755-89201956
            </p>
            <span className=" text-xs">工作时间 周一至周日 9:00 - 18:00</span>
          </div>
        </section>
      </Nav>
    </header>
  );
};

export default Header;

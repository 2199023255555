import { Link } from "react-router-dom";
import { Component } from "react";
/* 
  引入图片
*/
import wx_official_account_img from "../../assets/image/index/QR code Official.png";
import weibo_img from "../../assets/image/index/QR code Weibo.png";
import douyin_img from "../../assets/image/index/QR code Douyin.png";

import wx_nol from "../../assets/image/index/icon_weixin_nol.png";
import wx_pre from "../../assets/image/index/icon_weixin_pre.png";
import weibo_nol from "../../assets/image/index/icon_weibo_nol.png";
import weibo_pre from "../../assets/image/index/icon_weibo_pre.png";
import douyin_nol from "../../assets/image/index/icon_douyin_nol.png";
import douyin_pre from "../../assets/image/index/icon_douyin_pre.png";
import phone_nol from "../../assets/image/index/icon_dianhua_nol.png";
import phone_pre from "../../assets/image/index/icon_dianhua_pre.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav_arr: [
        {
          title: "景区合作",
          to: "/scenic_cooperation",
        },
		{
		  title: "智慧社区",
		  to: "/community_cooperation",
		},
        {
          title: "商家接入",
          to: "/store_linking",
        },
        {
          title: "地区招募",
          to: "/ecosystem_cooperation",
        },
        {
          title: "最新资讯",
          to: "/news",
        },
        {
          title: "关于我们",
          to: "/about",
        },
      ],
      official_accounts: [
        {
          title: "微信",
          icon: wx_nol,
          active_icon: wx_pre,
          qr_code: wx_official_account_img,
          is_active: true,
        },
        {
          title: "微博",
          icon: weibo_nol,
          active_icon: weibo_pre,
          qr_code: weibo_img,
          is_active: false,
        },
        {
          title: "抖音",
          icon: douyin_nol,
          active_icon: douyin_pre,
          qr_code: douyin_img,
          is_active: false,
        },
        {
          title: "0755-89201956",
          icon: phone_nol,
          active_icon: phone_pre,
          qr_code: wx_official_account_img,
          is_active: false,
        },
      ],
      showing_qr_code: wx_official_account_img,
    };
  }

  handleFooterIconHover = (index) => {
    const official_accounts = this.state.official_accounts;
    official_accounts.forEach((e) => {
      e.is_active = false;
    });
    official_accounts[index].is_active = true;
    this.setState({
      ...this.state,
      official_accounts,
      showing_qr_code: official_accounts[index].qr_code,
    });
  };
  handleScrollToTop() {
    document.documentElement.scrollTop = 0;
  }
  render() {
    return (
      <footer className="bg-black pb-28 pt-20">
        <section className="w-container flex text-main-green-400 mx-auto px-16">
          <section className="mr-auto">
            <ul className="flex mb-16">
              {this.state.nav_arr.map((e, i) => {
                return (
                  <li className="mr-8" key={i}>
                    <Link to={e.to} onClick={this.handleScrollToTop}>{e.title}</Link>
                  </li>
                );
              })}
            </ul>
            <p>景区码，开启智慧文旅新时代。<a href="http://beian.miit.gov.cn" target="_blank" rel="noreferrer">备案号: 粤ICP备16089746号-1</a></p>
          </section>
          <section className="flex">
            <img
              className=" mr-12 w-32 h-32"
              src={this.state.showing_qr_code}
              alt="qrcode"
            />
            <aside>
              <p>关注景区码</p>
              <ul>
                {this.state.official_accounts.map((e, i) => {
                  return (
                    <li
                      key={i}
                      className="flex items-center"
                      data-index={i}
                      onMouseEnter={() => {
                        this.handleFooterIconHover(i);
                      }}
                    >
                      <img
                        className="w-4 h-4 mr-2"
                        src={e.is_active ? e.active_icon : e.icon}
                        alt={e.title}
                      />
                      <span>{e.title}</span>
                    </li>
                  );
                })}
              </ul>
            </aside>
          </section>
        </section>
      </footer>
    );
  }
}
export default Footer;
